"use client"

import React from "react"
import { SessionProvider } from "next-auth/react"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"

import { store } from "@/lib/redux/store"

type sessionProps = {
  children: React.ReactNode
}

let persistor = persistStore(store)

function NextAuthSessionProvider({ children }: sessionProps) {
  return <SessionProvider>{children}</SessionProvider>
}

function ReduxProvider({ children }: sessionProps) {
  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        // loading={
        //   <div className="flex h-screen justify-center items-center">
        //     <div
        //       className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        //       role="status"
        //     >
        //       <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        //         Loading...
        //       </span>
        //     </div>
        //   </div>
        // }
      >
        {children}
      </PersistGate>
    </Provider>
  )
}
export { NextAuthSessionProvider, ReduxProvider }
