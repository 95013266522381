import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getCartCount = createAsyncThunk("cart/getCount", async () => {
  const res = await axios.get("/api/cart?countOnly=true")
  if (res.status === 200) {
    return res.data.data.count
  } else {
    return 0
  }
})

export const CartSlice = createSlice({
  name: "cart",
  initialState: {
    count: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCartCount.fulfilled, (state, action) => {
      state.count = action.payload
    })
  },
})

export default CartSlice.reducer
