import prisma from "@/lib/prisma"

const getAccountByUserId = async (userId: string) => {
  return prisma.account.findFirst({
    where: {
      userId,
    },
  })
}

const getUserById = async (id: string) => {
  try {
    const user = await prisma.user.findUnique({
      where: { id },
      include: {
        cart: true,
      },
    })
    return user
  } catch {
    return null
  }
}

export { getAccountByUserId, getUserById }
