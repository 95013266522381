import { auth } from "@/auth"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Session } from "next-auth"

export const getSession = createAsyncThunk("session/getSession", async () => {
  const session = await auth()
  if (session) {
    return session
  } else {
    return null
  }
})

export const SessionSlice = createSlice({
  name: "session",
  initialState: {
    sessionToken: null as Session | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSession.fulfilled, (state, action) => {
      state.sessionToken = action.payload
    })
  },
})

export default SessionSlice.reducer
