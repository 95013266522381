"use client"

import Image from "next/image"
import Link from "next/link"
import { motion } from "framer-motion"
import { LuFacebook, LuInstagram, LuLinkedin } from "react-icons/lu"
import { RiTwitterXLine, RiWhatsappLine } from "react-icons/ri"

import { Button } from "../ui/button"
import { LinkPreview } from "../ui/link-preview"

const navigation = {
  support: [
    { name: "Terms & Conditions", href: "/terms-and-conditions" },
    { name: "Privacy & Data Protection", href: "/privacy-policy" },
    { name: "Return & Refund", href: "/return-policy" },
    { name: "Payment Policy", href: "/payment-policy" },
    { name: "Shipping & Delivery", href: "/shipping-policy" },
    { name: "Helpdesk", href: "/help-desk" },
  ],
  aboutUs: [
    { name: "Contact Us", href: "/contact-us" },
    { name: "Bulk Orders", href: "/" },
    { name: "Zero Waste Initiative", href: "/" },
    { name: "Social Responsibility", href: "/" },
    { name: "Documents", href: "/" },
    { name: "FAQs", href: "/" },
  ],

  social: [
    {
      name: "Instagram",
      href: "https://www.instagram.com/alienwardrobe.co",
      icon: <LuInstagram className="h-5 w-5" aria-hidden="true" />,
    },
    {
      name: "X",
      href: "https://x.com/alien_wardrobe",
      icon: <RiTwitterXLine className="h-5 w-5" aria-hidden="true" />,
    },
    {
      name: "Whatsapp",
      href: "https://wa.me/918807065306",
      icon: <RiWhatsappLine className="h-5 w-5" aria-hidden="true" />,
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/alienwardrobe",
      icon: <LuFacebook className="h-5 w-5" aria-hidden="true" />,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/alien-wardrobe",
      icon: <LuLinkedin className="h-5 w-5" aria-hidden="true" />,
    },
  ],
}

const Footer = () => {
  return (
    <>
      <footer
        className="container md:px-0 bg-white"
        aria-labelledby="footer-heading"
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl pb-8">
          <div className="mt-16 border-y py-8 sm:mt-20 flex md:flex-row flex-col md:gap-0 gap-8 items-center justify-between lg:mt-24">
            <Link href="/">
              <span className="sr-only">Alien Wardrobe</span>
              <Image
                className="h-10 w-auto"
                src="/assets/images/logo.svg"
                alt="Alien Wardrobe"
                height={50}
                width={50}
              />
            </Link>
            <div className="flex justify-around md:space-x-6 w-full md:w-min">
              {navigation.social.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => window.open(item.href, "_blank")}
                  variant="outline"
                  size="icon"
                  className="rounded-full p-2 text-gray-700 hover:text-white hover:bg-green-500 border-gray-400"
                >
                  {item.icon}
                </Button>
              ))}
            </div>
          </div>
          <div className="xl:grid xl:grid-cols-3 xl:gap-8 sm:mt-14">
            <div className="grid grid-cols-2 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Support
                  </h3>
                  <ul role="list" className="mt-6 space-y-2">
                    {navigation.support.map((item) => (
                      <motion.li
                        key={item.name}
                        whileHover={{ x: 5 }}
                        transition={{ duration: 0.2, ease: "easeInOut" }}
                      >
                        <Link
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-green-500"
                        >
                          {item.name}
                        </Link>
                      </motion.li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    About Us
                  </h3>
                  <ul role="list" className="mt-6 space-y-2">
                    {navigation.aboutUs.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end gap-5 md:self-end self-center mt-10 xl:mt-0 ">
              <div className="flex gap-5">
                <Image
                  src={"/assets/images/footer_icon1.png"}
                  height={50}
                  width={50}
                  alt="Logo1"
                ></Image>

                <Image
                  src={"/assets/images/footer_icon2.png"}
                  height={50}
                  width={50}
                  alt="Logo1"
                ></Image>
              </div>
              <div className="border-t">
                <p className="mt-2 text-sm leading-6 md:text-end text-center text-gray-600">
                  Designed & Crafted with Love by Alien Wardrobe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
